import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a generic message box. Follows a Bootstrap pattern.
 */
class AlertElement extends HTMLElement implements CustomElement {
  public shadowRoot!: ShadowRoot;

  readonly dataset!: {
    alertPosition: 'left' | 'right';
    alertType: 'danger' | 'dark' | 'info' | 'light' | 'primary' | 'secondary' | 'success' |
      'warning';
    closable: string;
    fadeDelay: string;
    fadeOut: string;
  };

  private onCloseClickBound = this.onCloseClick.bind(this);
  private setFadeOutAttributeBound = this.setFadeOutAttribute.bind(this);
  private fadeRemoveTimer: ReturnType<typeof setTimeout>;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.shadowRoot.host.setAttribute('role', 'alert');
    this.render();
  }

  public disconnectedCallback() {
    const closeEl = this.shadowRoot.querySelector('.close-cta');
    closeEl?.removeEventListener('click', this.onCloseClickBound);
  }

  private onCloseClick(_event: Event) {
    // When the fade out attribute is set, it schedules a remove. Since we are removing immediately
    // it is important to cancel otherwise that scheduled remove causes a js error.
    clearTimeout(this.fadeRemoveTimer);

    safeRemoveElement(this);
  }

  private setFadeOutAttribute() {
    this.shadowRoot.host.setAttribute('data-fade-out', 'true');
    this.fadeRemoveTimer = setTimeout(safeRemoveElement, 2000, this);
  }

  private render() {
    if (this.dataset.closable === 'true') {
      const closeEl = this.shadowRoot.querySelector('.close-cta');
      closeEl.addEventListener('click', this.onCloseClickBound);
    }

    if (this.dataset.fadeDelay) {
      const fadeDelay = parseInt(this.dataset.fadeDelay, 10) || 5000;
      setTimeout(this.setFadeOutAttributeBound, fadeDelay);
    }
  }
}

/**
 * This exists as a helper to remove the node that does a check if still connected. This way this
 * callback can be scheduled to run in a way that mitigates an issue where by the time it does run
 * the node has already been removed. When the node has already been detached, trying to remove it
 * again triggers a dom exception (a NotFoundError). We detect the node is already detached by
 * checking that the node has a parent node. If detached then do nothing.
 */
function safeRemoveElement(element: Element) {
  if (element.parentNode) {
    element.remove();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'alert-element': AlertElement;
  }
}

if (!customElements.get('alert-element')) {
  customElements.define('alert-element', AlertElement);
}
